import {useContext} from 'react'
import CookieConsentContext from '../../contexts/cookie-consent-context'

export function useCookieConsent() {
  const context = useContext(CookieConsentContext)

  if (!context) {
    throw new Error(
      `'useCookieConsent' should be used in a CookieConsentContext.`,
    )
  }

  return context
}

export default useCookieConsent
