import clsx from 'clsx'
import {LinkProps} from 'next/link'
import {HTMLProps, MouseEventHandler} from 'react'
import Link from '../link'

export interface ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'type'>,
    Pick<LinkProps, 'prefetch'> {
  /**
   * Determines how prominent this button should be.
   *
   * @default 'secondary'
   */
  variant?: 'primary' | 'secondary' | 'inverse' | 'borderless'
  /**
   * Determines the native type of this button.
   *
   * @default 'button'
   */
  type?: 'button' | 'submit' | 'reset'
  /**
   * Determines which type of padding to use. Default is larger on the left and
   * right sides.
   *
   * @default 'default'
   */
  padding?: 'default' | 'same'
  /**
   * Determines the environment in which the button is being used.
   *
   * @default 'light'
   */
  environment?: 'dark' | 'light'
  /**
   * Determines whether instead of a button, a link should be rendered.
   *
   * @default false
   */
  renderAsLink?: boolean
  /**
   * Determines whether default styles should be disabled.
   *
   * @default false
   */
  disableDefaultStyles?: boolean
}

export function Button({
  children,
  className,
  type,
  variant = 'secondary',
  padding = 'default',
  environment = 'light',
  renderAsLink,
  href,
  disableDefaultStyles,
  onClick,
  rel,
  target,
  prefetch,
  ...props
}: ButtonProps) {
  const classNames = disableDefaultStyles
    ? className
    : clsx(
        variant === 'primary' &&
          'bg-primary text-white motion-safe:transition-all',
        variant === 'inverse' &&
          'bg-gradient-to-br from-inverse to-inverse-light text-white motion-safe:transition-all',
        (variant === 'primary' || variant === 'inverse') &&
          !props.disabled &&
          'hover:bg-primary-dark active:bg-primary-deep',
        variant === 'secondary' && 'border-2 border-solid',
        variant === 'secondary' && environment === 'light' && 'border-dark',
        variant === 'secondary' &&
          environment === 'dark' &&
          'border-white bg-transparent',
        (variant === 'secondary' || variant === 'borderless') &&
          environment === 'light' &&
          !props.disabled &&
          'text-dark hover:bg-gray-100 active:bg-gray-200',
        (variant === 'secondary' || variant === 'borderless') &&
          environment === 'dark' &&
          !props.disabled &&
          'text-white hover:bg-white hover:bg-opacity-20 active:bg-opacity-5',
        'font-semibold rounded-lg h-auto',
        !className?.includes('transition-all') && 'transition-colors',
        padding === 'default' &&
          (variant === 'primary' || variant === 'inverse')
          ? 'py-3 px-4'
          : 'p-3',
        padding === 'default' && variant === 'secondary'
          ? 'py-2.5 px-4'
          : 'p-3',
        props.disabled ? 'cursor-default grayscale' : 'cursor-pointer',
        className,
      )

  if (renderAsLink && href) {
    const safeOnClick =
      onClick as unknown as MouseEventHandler<HTMLAnchorElement>

    return (
      <Link
        href={href}
        className={classNames}
        disableDefaultStyles
        onClick={safeOnClick}
        rel={rel}
        target={target}
        prefetch={prefetch}
      >
        {children}
      </Link>
    )
  }

  return (
    <button type={type} className={classNames} onClick={onClick} {...props}>
      {children}
    </button>
  )
}

export default Button
