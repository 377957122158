import clsx from 'clsx'
import {HTMLProps} from 'react'

export interface ParagraphProps
  extends Omit<HTMLProps<HTMLParagraphElement>, 'color'> {
  /**
   * Font size of paragraph. This should be a Tailwind class.
   *
   * @default 'text-lg'
   */
  fontSize?: string
  /**
   * Determines whether text should be rendered with dark or light font color.
   *
   * @default dark
   */
  color?: 'dark' | 'light' | string
}

export function Paragraph({
  className,
  children,
  fontSize = 'text-lg',
  color = 'dark',
  ...props
}: ParagraphProps) {
  return (
    <p
      className={clsx(
        'leading-normal',
        color === 'light'
          ? 'text-white'
          : color === 'dark'
          ? 'text-typography'
          : color,
        fontSize,
        className,
      )}
      {...props}
    >
      {children}
    </p>
  )
}

export default Paragraph
