import clsx from 'clsx'
import {createElement, ForwardedRef, forwardRef, HTMLProps} from 'react'

export interface ContainerProps extends HTMLProps<Omit<HTMLDivElement, 'ref'>> {
  /**
   * Native HTML component to render.
   *
   * @default 'div'
   */
  component?: string
  disableDefaultPadding?: boolean
}

export const Container = forwardRef(function Container(
  {
    component = 'div',
    disableDefaultPadding,
    className,
    children,
    ...props
  }: ContainerProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return createElement(
    component,
    {
      className: clsx(
        'container max-w-7xl mx-auto',
        !disableDefaultPadding && 'px-4 sm:px-5 md:px-6',
        className,
      ),
      ref,
      ...props,
    },
    children,
  )
})

export default Container
