import clsx from 'clsx'
import {createElement, HTMLProps} from 'react'

/**
 * Available native heading variants.
 */
export type HeadingVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

/**
 * Describes how the font size should be configured based on the selected
 * heading variant.
 */
const fontSizeMapping: Record<HeadingVariants, string> = {
  h1: 'text-4xl md:text-5xl',
  h2: 'text-3xl md:text-4xl',
  h3: 'text-xl md:text-2xl',
  h4: 'text-lg',
  h5: 'text-base',
  h6: 'text-sm',
}

export interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  /**
   * Native HTML component to render.
   *
   * @default 'h1'
   */
  variant?: HeadingVariants
  /**
   * Component to render even if variant specifies some different styling.
   *
   * @default null
   */
  component?: string | HeadingVariants
  /**
   * Determines whether default max width should be disabled or not.
   *
   * @default false
   */
  disableMaxWidth?: boolean
}

export function Heading({
  variant = 'h1',
  component,
  children,
  className,
  disableMaxWidth,
  ...props
}: HeadingProps) {
  return createElement(
    component ?? variant,
    {
      className: clsx(
        fontSizeMapping[variant],
        'block font-heading leading-normal md:leading-normal',
        !disableMaxWidth && 'max-w-sm',
        !className?.includes('text-white') && 'text-typography-title',
        className,
      ),
      ...props,
    },
    children,
  )
}

export default Heading
