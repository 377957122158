import clsx from 'clsx'
import NextLink, {LinkProps as NextLinkProps} from 'next/link'
import {useRouter} from 'next/router'
import {HTMLProps, PropsWithChildren} from 'react'

export type FontWeight = 'semibold' | 'normal'

export interface LinkProps
  extends PropsWithChildren<NextLinkProps>,
    Pick<
      HTMLProps<HTMLAnchorElement>,
      'onClick' | 'rel' | 'target' | 'hrefLang'
    > {
  /**
   * Class name to be passed down to the link component.
   */
  className?: string
  /**
   * Font weight of this link.
   *
   * @default 'bold'
   */
  fontWeight?: FontWeight
  /**
   * Determines whether link should be rendered using the active, highlighted
   * state by default.
   *
   * @default false
   */
  active?: boolean
  /**
   * Link variant which determines which colors the component will use.
   *
   * @default 'primary'
   */
  variant?: 'primary' | 'secondary'
  /**
   * Determines whether link should have an underline if its active.
   *
   * @default true
   */
  shouldDisplayActiveUnderline?: boolean
  /**
   * Determines whether link should display active status.
   *
   * @default false
   */
  disableActiveState?: boolean
  /**
   * Determines whether default styles should be disabled.
   *
   * @default false
   */
  disableDefaultStyles?: boolean
  /**
   * Value is passed to native element's aria-label property.
   *
   * @default null
   */
  ariaLabel?: string
}

export function Link({
  className,
  children,
  fontWeight = 'semibold',
  href,
  active,
  onClick,
  variant = 'primary',
  shouldDisplayActiveUnderline = true,
  disableActiveState,
  disableDefaultStyles,
  ariaLabel,
  prefetch,
  ...props
}: LinkProps) {
  const stringifiedHref = href.toString()
  const router = useRouter()
  const activePath = router.asPath
  const isHomeLink = stringifiedHref === '/'

  const isActive =
    !disableActiveState &&
    ((isHomeLink && activePath === '/') ||
      (!isHomeLink && activePath.startsWith(stringifiedHref)) ||
      active)

  const classNames = disableDefaultStyles
    ? className
    : clsx(
        fontWeight === 'semibold' ? 'font-semibold' : 'font-normal',
        isActive && variant === 'primary' && 'text-primary',
        isActive && shouldDisplayActiveUnderline && 'border-b-2 border-solid',
        isActive &&
          variant === 'primary' &&
          shouldDisplayActiveUnderline &&
          'border-primary',
        isActive &&
          variant === 'secondary' &&
          shouldDisplayActiveUnderline &&
          'border-secondary',
        isActive && variant === 'secondary' && 'text-secondary',
        variant === 'primary'
          ? 'hover:text-primary active:text-primary-dark'
          : 'hover:text-secondary active:text-secondary-dark',
        'transition-colors',
        className,
      )

  return (
    <NextLink href={href} prefetch={prefetch}>
      <a
        aria-label={ariaLabel}
        className={classNames}
        onClick={onClick}
        {...props}
      >
        {children}
      </a>
    </NextLink>
  )
}

export default Link
